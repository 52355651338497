.butaddrec {
    padding: 10px 40px;
    background-color: #D61355;
    color: white;
    border: none;
    border-radius: 5px;
    font-family: "Raleway", sans-serif;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
  }
  
  .butaddrec:hover {
    background-color: #f14a82;
  }
  
  .cont1 {
    background-color: rgb(105, 106, 107);
    width: 40%;
    position: absolute;
    left: 30%;
    padding: 20px;
    margin-top: 20px;
    border-radius: 5px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .cont1 h2 {
    margin-bottom: 20px;
  }
  .upload1{
    margin-top: 15px;
  }
  .canbusiness{
    padding: 10px 17px;
    background-color:#D61355;
    color: white;
    border: none;
    border-radius: 5px;
    font-family: "Raleway", sans-serif;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    margin-top: 15px;
  }
  .canbusiness:hover{
    background-color: #f83d7b;
  }
  .searchbp{
position: relative;
top: 50%;
left: 75%;
width: 20%;
border: 1px solid black;
border-radius: 5px;
padding: 10px 30px;
  }
  .uploadfile{
    padding: 10px 45px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
    margin-top: 10px;
}
.upload1{
  padding: 10px 17px;
  background-color: #007bff;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  margin-top: 10px;
} 