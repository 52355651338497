
table {
    position: relative;
    top:50px;
    border-collapse: collapse;
    width: 80%;
    margin-top: 20px;
  }
  
  th, td {
    text-align: left;
    padding: 8px;
    border: 1px solid #ddd; /* Add border to cells */
  }
  
  th {
    background-color: #f2f2f2;
    font-weight: bold;
    border: 1px solid #ddd; /* Add border to header cells */
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  img {
    width: 100px;
  }
  .add {
    position: fixed;
    display:flex;
    top: 92px;
    left: 100px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  .form-overlay1 {
    position: absolute;
    top: 100px;
    left: 380px;
    width: 50%;
    height: auto;
    background-color: rgba(110, 109, 109, 0.973);
    background: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .form-group{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
  }
  label {
    font-weight: bold;
    margin-right: 10px;
    flex-basis: 30%;
  }
  .fnameed, .lnameed, .emailed, .phoneed, .bdayed, .ageed, .gendered, .usernameed,
select {
  flex-basis: 70%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  font-size: 16px;
}

  select {
    appearance: none;
    -webkit-appearance: none;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5z"/></svg>') no-repeat right center;
    background-size: 20px;
  }
  .search{
    padding: 10px;
    border: none;
    border-radius: 5px;
    box-shadow: 0 0 5px rgb(0 0 0 / 20%);
    width: 100%;
    max-width: 500px;
    font-size: 16px;
    margin-top: 5px;
    position: fixed;
    left: 225px;
  }
.email1,.firstname1,.lastname1,.phone1,.birth1,.age1,.gender1,.username1,.password1,.cpassword1,.valid1{
  margin-top: 10px;
  font-family: 'Poppins', sans-serif;
  padding: 10px 45px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
}
.addnew{
  position: relative;
  font-size: 30px;
  font-weight: bold;
  color: white;
  left: 80px;
  margin-top: 10px;
}
.usersub{
  padding: 10px 22px;
  margin-right: 10px;
  background-color: #007bff;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  margin-top: 10px;
}
/* CSS */
.fadeAnim{
  animation-name: flyIn;
  animation-duration: 1s; /* Set the duration of the animation */
  animation-fill-mode: forwards; /* After the animation ends, keep the styles applied */
}
@keyframes flyIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}


