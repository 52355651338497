@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400&family=Raleway:wght@300&display=swap');

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Raleway', sans-serif;
}

/* Color and styling */

:root{
  --PrimaryColor: hsl(199, 100%, 33%);
  --SecondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%), hsl(199, 100%, 33%));
  --blueColor: rgb(0, 123, 255);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(200, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 240, 246);
  --cardBG: rgb(225, 225, 235);
}

a{
  text-decoration: none;
}

li{
  list-style: none;
}

.section{
  padding: 1rem 0 4rem;
}

.container{
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.icon{
  font-size: 2rem;
  cursor: pointer;
}

.flex{
  display: flex;
  align-items: center;
}

.grid{
  display: grid;
  align-items: center;
}

.btn{
  padding: .6rem 1.5rem;
  background: var(--blueColor);
  border-radius: 3rem;
  border: none;
  outline: none;
  cursor: pointer;
}

.btn a{
  color: var(--whiteColor);
  font-weight: 500;
}

.btn:hover{
  background: #0062cc;
}

img, video{
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

input{
  font-size: 100%;
}

body{
  background: var(--bodyColor);
}


/* Media Queries */

@media screen and (min-width: 550px){
  .container{
    width: 95% !important;
    margin: auto !important;
  }

  .section{
    padding: 1rem 0 4rem;
  }
};

@media screen and (min-width: 1240px){
  .container{
    width: 95% !important;
    margin: auto !important
  }

  .section{
    padding: 1rem 0 4rem;
  }
};
.mainfooter{
  position: relative;
  top: 600px;
}

/* .banner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}
.loginbtn{
  border: 1px solid black;
  text-decoration: none;
  color:white;
  border-radius: 20px;
  width: 100px;
  padding: 10px;
  background-color: #060047;
  text-align: center;
  
  position: relative;
}

.banner img {
  width: 100%;
  height: auto;
  object-fit: cover;
} */

/* Media queries for responsive design */
/* @media only screen and (max-width: 768px) {
  .banner {
    height: 300px;
  }
}

@media only screen and (max-width: 480px) {
  .banner {
    height: 200px;
  }
} */