/* @tailwind base;
@tailwind components;
@tailwind utilities; */


.about-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.video {
  flex: 1;
  margin-right: 20px;
}

.about-us {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: "Raleway", sans-serif;
    color: #333;
    text-align: justify;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }
  .carousel-container img {
    width: 100%;
    height: 250px;
  }
  
  
.About, h2 {
    font-weight: bold;
    text-align: center;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  li {
    margin-bottom: 10px;
  }

  

/* Media queries */

@media only screen and (max-width: 576px) {
  .carousel-container {
  width: 100%;
  padding-top: 30px;
  }

  .carousel-container img {
    width: 100%;
    height: 200px;
  }

  .about-us {
    width: 50%;
    float: left;
    }

  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    .carousel-container {
    width: 100%;
    padding-top: 30px;
    }
  
    .carousel-container img {
      width: 100%;
      height: 200px;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  
    .about-us {
      width: 50%;
      float: left;
      padding-left: 2rem;
      padding-right: 2rem;
      }
  
    }
  
  @media only screen and (min-width: 769px) {
  .carousel-container {
  width: 50%;
  float: left;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  }

  .carousel-container img {
    width: 100%;
    height: 250px;
  }
  
  .about-us {
  width: 50%;
  float: left;
  padding-left: 1rem;
  padding-right: 1rem;
  }
  }