.contact-us-container {
  max-width: 600px;
  margin: 50px auto;
  /* padding: 50px; */
  padding: 20px 60px 20px 60px;
  background-color: #f2f2f2;
}

.contact-us-container h1 {
  text-align: center;
  font-size: 30px;
  margin-bottom: 20px;
}

.contact-us-container form {
  display: flex;
  flex-direction: column;
}

.contact-us-container label {
  font-size: 15px;
  margin-bottom: 10px;
}

.contact-us-container input, .contact-us-container textarea { 
  padding: 10px;
  font-size: 15px;
  margin-bottom: 20px;
  /* border-radius: 5px; */
  resize: none; 
  width: 100%;
  /* height: 200px; */

  /* TEST */
  /* max-width: 100%;
  max-height: 300px; */
}

.contact-us-container button {
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  width: 150px;
  position: relative;
  left: 25%;
  margin-top: auto !important;
}

.inline-block-paragraph{
  font-size: 12px;
  text-align: center;
  margin: 1rem 0 0 0;
}

@media (max-width: 600px) {
  .contact-us-container {
    padding: 10px;
  }

  .contact-us-container h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .contact-us-container input,
  .contact-us-container textarea {
    font-size: 14px;
  }
}