.forgot-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.forgot-password-form {
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
}

.forgot-password-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.input-container {
  margin-bottom: 15px;
}

.username-input {
  width: 50%;
  
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.error-message {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.error-icon {
  color: red;
  margin-right: 5px;
}

.error-text {
  color: red;
}

.back-to-login {
  font-weight: bold;
  color: black;
  text-decoration: none;
  display: block;
  text-align: center;
  margin-top: 10px;
}
.respass{
  padding: 10px 22px;
  margin-right: 10px;
  background-color: #007bff;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  margin-top: 10px;
}