.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  
  .form-container input[type="text"],
  .form-container input[type="number"],
  .form-container input[type="file"] {
    font-family: 'Poppins', sans-serif;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 300px;
    max-width: 100%;
  }
  
  .form-container input[type="submit"] {
    margin: 10px;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  .form-container input[type="submit"]:hover {
    background-color: #45a049;
  }
  
  .form-container .image-preview {
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    margin: 10px;
    display: flex;
    align-items: center; 
    justify-content: center;
  }
  
  .form-container .image-preview img {
    max-width: 100%;
    max-height: 100%;
  }
  
  .form-container .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: #aaa;
    outline: none;
  }
  
  .form-container .close-button:hover {
    color: #555;
  }
  .form-overlay {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 126, 126, 0.904);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img{
    width: 100%;
    height: 100%;
  }
  textarea {
    margin-top: 10px;
    height: 200px;
    width:500px;
  }
 .imahe{
  margin-top: 10px;
  background-color: white;
 }
 .starttime{
  font-family: 'Poppins', sans-serif;
  margin-top: 10px;
  padding: 10px 45px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
 }
 .endtime{
  font-family: 'Poppins', sans-serif;
  margin-top: 10px;
  padding: 10px 45px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;  
 }
.remove{
  background-color: #FC2947;
  border: solid 1px black;
  padding: 5px;
  border-radius: 5px;
} 
.remove:hover{
  background-color: #eb0753;
}
 .addact{
  background-color: #13aaf0;
  border: solid 1px black;
  cursor: pointer;
  font-weight: bold;
  border-radius: 10px;
 }
 .addact:hover{
  background-color: #07587e;
 }
 .addday{
  margin-top: 10px;
  border: solid 1px black;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  background-color: #45a049;
 }
 .addday:hover{
  background-color: #4CAF50;
 }
 .sub{
  padding: 10px 22px;
  margin-right: 10px;
  background-color: #007bff;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  margin-top: 10px;
 }
 .sub:hover{
  background-color: #07587e;
 }
 .can{
  margin-top: 10px;
  border: none;
  padding: 10px 22px;
  border-radius: 5px;
  background-color: #FC2947;
  color: white;
  font-size: 16px;
  margin-bottom: 10px;
  cursor: pointer;
 }
 .can:hover{
  background-color: #eb0753;
 }
 .packname{
  position: relative;
  border: none;
  padding:10px 50px;
  border-radius: 5px;
 }
 .packinc{
  border: none; 
  border-radius: 5px;
 }
 .price1{
  position: relative;
  font-family: 'Poppins', sans-serif;
  border: none;
  padding:10px 30px;
  border-radius: 5px;
 }
 .imahe{
  position: relative;
  border: none;
  padding:10px;
  border-radius: 5px;
 }
 .actname{
  border: none;
  padding:10px 45px;
  border-radius: 5px;
 }
 .clickable-button {
  margin: 5px;
  background-color: rgb(214, 19, 85);
  color: rgb(255, 255, 255);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/* Active button styles */
.clickable-button.active {
  background-color: rgb(3, 3, 3);
  color: rgb(214, 19, 85);
}

/* Animation */
@keyframes movingEffect {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

/* Animation effect for active buttons */
.clickable-button.active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
  animation: movingEffect 2s linear infinite;
}