

h1 {
  font-size: 36px;
}

h2 {
  font-family: "Raleway", sans-serif;
  font-size: 24px;
}

.heading {
  width:100%;
 

  object-position: center;
}

.box1 {
  border: solid black 1px;
  border-radius: 20px;
  padding: 20px;
}

.box2 {
  border: solid black 1px;
  border-radius: 5px;
  padding: 20px;
}

.box3 {
  border: solid black 1px;
  height: 500px;
  overflow-y: auto;
  padding: 20px;
  background-color: azure;
}

.but1 {
  background-color: #192e5b;
  padding: 12px 24px;
  border: solid 1px black;
  color: aliceblue;
  font-size: 16px;
  font-weight: bold;
  border-radius: 10px;
  margin: 20px auto;
  display: block;
}

.day-button {
  margin-right: 8px;
  margin-bottom: 10px;
  padding: 10px 20px;
  cursor: pointer;
  background: #116d6e;
  border-radius: 5px;
}

.day-button.active {
  background: #068da9;
}

.day-button:hover {
  background: #068da9;
}

/* Responsive Styles */
@media screen and (max-width: 767px) {
  .heading {
    height: 200px;
  }
}
